import React from 'react';
import { NavLink } from 'react-router-dom';
import './SideDrawer.scss';
import cv from "../../Bodart_raphael_CV.pdf";

const sideDrawer = props => (
    <nav className='side-drawer'>
        <ul className='main-container-list-menu-burger'>
            <li className="list-menu-burger"><NavLink to='/homePage' activeClassName='active-link' className="menu-burger home-button" href="/" onClick={props.click}> Home </NavLink></li>
            <li className="list-menu-burger"><NavLink to='/portfolio' activeClassName='active-link' className="menu-burger portefolio-button" onClick={props.click}>Portfolio</NavLink></li>
            <li className="list-menu-burger"><a href={cv} target="_blank" rel="noreferrer" activeClassName='active-link' className="menu-burger resume-button" onClick={props.click}>CV</a></li>
            <li className="list-menu-burger"><NavLink to='/about' activeClassName='active-link' className="menu-burger about-button" onClick={props.click}>À propos</NavLink></li>
            <li className="list-menu-burger"><NavLink to='/contact' activeClassName='active-link' className="menu-burger contact-button" onClick={props.click}>Contact</NavLink></li>
        </ul>
    </nav>
);



export default sideDrawer;